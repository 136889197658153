@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  image-rendering: auto;
  image-rendering: pixelated;

  /* Safari seems to support, but seems deprecated and does the same thing as the others. */
  image-rendering: -webkit-optimize-contrast;
}

.fixed-width {
  width: 125px;
  display: flex;   
  justify-content: center;
  align-items: center;
  text-align: center;
}

.hovering:hover {
  cursor: pointer;
  border: 2px solid black;
}

.tinytext {
  font-size: 12px;
  line-height: 105%;
}

.bg-grey-c-1 {
  background-color: #EFEFEF;
}

.border-red-c-1 {
  border: solid 1px #B03032;
  color: #B03032;
}

.bg-attentionblue {
  background-color: #3474B2;
}

.margined {
  margin: 0 auto;
}